<template>
  <div>
    <PageDynamicBreadcrumb />
    <PageHeader title="Inventory Source Import History"></PageHeader>
    <div class="row items-center justify-start q-mb-lg">
      <DateRangeSelector
        :initial-start-date="startDate"
        :initial-end-date="endDate"
        @update="
          dates => {
            updateDateRange(dates);
            getImportLog();
          }
        "
        url-state
      />
    </div>

    <DataTable
      table-key="InventorySourcesImportHistory"
      row-key="id"
      :title="title"
      :columns="columns"
      :rows="importHistory"
      :loading="loading"
      :pagination="{
        sortBy: 'time_created',
        descending: true,
        page: 1,
        rowsPerPage: 25
      }"
      :downloadable="true"
      :refreshable="true"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
      @update-rows="getImportLog()"
    >
      <template v-slot:status="row">
        <div>
          <q-icon
            class="q-mr-sm"
            :color="row.statusDetails.color"
            :name="row.statusDetails.icon"
            size="xs"
            style="margin-top: -3px"
          />
          <span
            :class="'text-' + row.statusDetails.color"
            class="text-weight-medium"
            style="font-size: 90%"
            v-html="row.statusDetails.message"
          />
          <span
            v-if="row.failed_reason == 'Analysis Failed'"
            class="text-subtle"
          >
            <div>
              <strong>{{
                Intl.NumberFormat().format(
                  parseInt(row.analysis_log_data.records_passed)
                )
              }}</strong>
              records passed
            </div>
            <div>
              <strong>{{
                Intl.NumberFormat().format(
                  parseInt(row.analysis_log_data.records_failed)
                )
              }}</strong>
              records failed
            </div>
          </span>
        </div>
      </template>
      <template v-slot:results="row">
        <div
          v-if="
            row.failed === 0
          "
        >
          -
        </div>

        <div v-else>
          <div>
            <strong>
              {{ Intl.NumberFormat().format(parseInt(row.feed_total)) }}
            </strong>
            records in file
          </div>
          <div>
            <strong>
              {{
                Intl.NumberFormat().format(parseInt(row.products_active_total))
              }}
            </strong>
            products found in file
          </div>
          <div>
            <strong>
              {{
                Intl.NumberFormat("en-US").format(
                  parseInt(row.products_created) +
                    parseInt(row.products_updated)
                )
              }}
            </strong>
            products updated or added
          </div>
          <div>
            <strong>
              {{
                Intl.NumberFormat("en-US").format(
                  parseInt(row.products_skipped)
                )
              }}
            </strong>
            products rejected due to errors
          </div>
          <div>
            <strong>
              {{
                Intl.NumberFormat("en-US").format(
                  parseInt(row.products_deactivated)
                )
              }}
            </strong>
            products removed
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import PageHeader from "@/components/UI/PageHeader";
import axios from "axios";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import DateRangeSelector from "@/components/UI/DateRangeSelector";
import DataTable from "@/components/DataTable";
moment.locale("en");

export default {
  name: "BusinessInventoryFeedImportHistory",
  components: {
    DataTable,
    DateRangeSelector,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      title: "Imports",
      loading: true,
      startDate: this.$route.query.start_date
        ? this.$route.query.start_date
        : moment()
            .subtract(7, "d")
            .format("YYYY-MM-DD"),
      endDate: this.$route.query.end_date
        ? this.$route.query.end_date
        : moment().format("YYYY-MM-DD"),
      datePreset: this.$route.query.date_preset
        ? this.$route.query.date_preset
        : "",
      history: [],
      columns: [
        {
          name: "time_created",
          label: "Time Started",
          field: row => moment.utc(row.time_created).local(),
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          align: "left",
          type: "metric",
          metricType: "date"
        },
        {
          name: "status",
          label: "Status",
          field: "status",
          hideField: true,
          type: "dimension",
          sortable: true
        },
        {
          name: "time_completed",
          label: "Time Completed",
          field: row =>
            row.time_completed ? moment.utc(row.time_completed).local() : "",
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          align: "left",
          type: "metric",
          metricType: "date"
        },
        {
          name: "results",
          label: "Results",
          field: "",
          hideField: true,
          sortable: false,
          filterable: false
        },
        {
          name: "feed_total",
          label: "Records In File",
          field: "feed_total",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_active_total",
          label: "Products Found In File",
          field: "products_active_total",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_updated_or_added",
          label: "Products Updated Or Added",
          field: row =>
            parseInt(row.products_created) + parseInt(row.products_updated),
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_skipped",
          label: "Products Rejected",
          field: "products_skipped",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_deactivated",
          label: "Products Removed",
          field: "products_deactivated",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        }
      ]
    };
  },
  computed: {
    importHistory() {
      return this.loading ? [] : this.history;
    }
  },
  mounted() {
    axios({
      url: "https://api-v2.jumpfeed.com/internal/inventory-sources"
    })
      .then(resp => {
        let title = resp.data.data.filter(
          source => source.id === this.$route.params.inventorySourceId)[0].name;
        this.title = title + " Imports";
      })
      .catch(err => {
        console.log(err);
      });

    this.getImportLog();
  },
  beforeMount() {
    if (this.datePreset.length > 0) {
      let dateRange = this.getDatePresetRange(this.datePreset);
      this.startDate = dateRange.from;
      this.endDate = dateRange.to;
    }
  },
  methods: {
    getImportLog() {
      this.loading = true;
      axios({
        url: "https://api-v2.jumpfeed.com/internal/inventory-sources/import-history",
        params: {
          inventory_source_id: this.$route.params.inventorySourceId,
          start_date: moment(this.startDate).format("YYYY-MM-DD"),
          end_date: moment(this.endDate).format("YYYY-MM-DD")
        }
      })
        .then(resp => {
          let history = resp.data.data;

          this.history = history.map(row => {
            row.statusDetails = {
              color: "",
              icon: "",
              message: ""
            };
            row.status = "";

            if (
              row.failed == 0
            ) {
              row.status = "Successful";
              row.statusDetails.color = "positive";
              row.statusDetails.icon = "check_circle";
              row.statusDetails.message = "Finished Successfully";
            } else if (row.failed == 1 && row.failed_reason) {
              row.status = "Failed";
              row.statusDetails.color = "negative";
              row.statusDetails.icon =
                row.failed_reason == "Unknown Error Occurred"
                  ? "bug_report"
                  : "highlight_off";
              row.statusDetails.message = row.failed_reason;
            } else if (
              moment.utc().diff(moment.utc(row.time_created), "hours") < 12
            ) {
              row.status = "Importing";
              row.statusDetails.color = "subtle";
              row.statusDetails.icon = "downloading";
              row.statusDetails.message = "Importing...";
            } else {
              row.status = "Unknown Error Occurred";
              row.statusDetails.color = "negative";
              row.statusDetails.icon = "bug_report";
              row.statusDetails.message = "Unknown Error Occurred";
            }
            return row;
          });

          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
